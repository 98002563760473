import {Ref, ref } from 'vue';
import { $api, $loading } from '@/api/index';
import { toast } from '@/utils/toast';

export interface ChulhaInfo {
    saleName: string;
    saDateNm: string;
    dplaceName: string;
    itnm: string;
    saleQunt: number;
    errChk: string;
    txtMsg: string;
    saTime: string;
    saCarno: string;
    saKisa: string;
    saleTwgh: number;
    saleGwgh: number;
}

export interface ChulhaSale{
  saleName: string;
  saleCode: string;
}

export interface DayDplaceList{
  dplaceName: string;
  
}


export function chulhas(){
    
    const isConnected = ref(true);
    const chulhalist: Ref<ChulhaInfo[]> = ref([]);
    const salelist: Ref<ChulhaSale[]> = ref([]);
    const daydplacelist: Ref<DayDplaceList[]> = ref([]);

      async function fetchDatas(frDate: string, toDate: string, saleCd: string, cate: string) {
        await $loading(`/chulhaday?frDate=${frDate}&toDate=${toDate}&saleCd=${saleCd}&cate=${cate}`, 'POST').then(
          (res: any) => {
            if(res.data == ''){
              chulhalist.value = [];
              toast('데이터가 존재하지 않습니다.'); 
            }else{
              if(res.data[0].errChk == -1){
                toast(res.data[0].txtMsg); 
              }else{
                chulhalist.value = res.data;
              }
              
            }
            
          }
        );
      }

      async function fetchSaleDatas() {
        await $loading(`/chulhaday`, 'GET').then(
          (res: any) => {
            salelist.value = res.data;
          }
        );
      }

      async function fetchDplaceDatas() {
        await $api(`/daydplcelist`, 'POST').then(
          (res: any) => {
            daydplacelist.value = res.data;
          }
        );
      }
      

      async function fetchDatasMon(frDate: string, saleCd: string) {
        await $loading(`/chulhamonth?frDate=${frDate}&saleCd=${saleCd}`, 'POST').then(
          (res: any) => {
            if(res.data == ''){
              chulhalist.value = [];
              toast('데이터가 존재하지 않습니다.'); 
            }else{
              if(res.data[0].errChk == -1){
                toast(res.data[0].txtMsg); 
              }else{
                chulhalist.value = res.data;
              }
              
            }
            
          }
        );
      }

      
    
    return { isConnected, chulhalist, fetchDatas, fetchSaleDatas, salelist, fetchDatasMon, fetchDplaceDatas, daydplacelist};
}
